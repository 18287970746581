import React from "react";
import { Link } from "react-router-dom";

const Carousel = () => {
  return (
    <section className="container-fluid">
      <div className="cs_slider position-relative">
        <div
          className="cs_slider_container"
          data-autoplay={1}
          data-loop={1}
          data-speed={700}
          data-center={0}
          data-slides-per-view={1}
          data-fade-slide={1}
        >
          <div className="cs_slider_wrapper">
            <div className="slick_slide_in">
              <div className="cs_hero cs_style_2 position-relative">
                <div className="cs_hero_left">
                  <div className="cs_hero_text">
                    <h4 className="cs_hero_title_mini cs_fs_28">
                      Up to 50% Off
                    </h4>
                    <h1 className="cs_hero_title cs_fs_67 cs_bold">
                      Elegant men collection in summer season
                    </h1>
                    <Link
                      to="/shop"
                      className="cs_btn cs_style_1 cs_fs_16 cs_medium"
                    >
                      See Collection
                    </Link>
                  </div>
                </div>
                <div className="cs_hero_right">
                  <div className="cs_hero_thumb">
                    <img src="https://images.pexels.com/photos/3610753/pexels-photo-3610753.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Thumb" />
                  </div>
                </div>
              </div>
            </div>
            <div className="slick_slide_in">
              <div className="cs_hero cs_style_2 position-relative">
                <div className="cs_hero_left">
                  <div className="cs_hero_text">
                    <h4 className="cs_hero_title_mini cs_fs_28">
                      Up to 20% Off
                    </h4>
                    <h1 className="cs_hero_title cs_fs_67 cs_bold">
                      Elegant woman collection in summer
                    </h1>
                    <Link
                      to="/shop"
                      className="cs_btn cs_style_1 cs_fs_16 cs_medium"
                    >
                      See Collection
                    </Link>
                  </div>
                </div>
                <div className="cs_hero_right">
                  <div className="cs_hero_thumb">
                    <img src="https://images.unsplash.com/photo-1635778976124-f609898bafc2?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Thumb" />
                  </div>
                </div>
              </div>
            </div>
            <div className="slick_slide_in">
              <div className="cs_hero cs_style_2 position-relative">
                <div className="cs_hero_left">
                  <div className="cs_hero_text">
                    <h4 className="cs_hero_title_mini cs_fs_28">
                      Up to 10% Off
                    </h4>
                    <h1 className="cs_hero_title cs_fs_67 cs_bold">
                      Children collection in winter season
                    </h1>
                    <Link
                      to="/shop"
                      className="cs_btn cs_style_1 cs_fs_16 cs_medium"
                    >
                      See Collection
                    </Link>
                  </div>
                </div>
                <div className="cs_hero_right">
                  <div className="cs_hero_thumb">
                    <img src="https://images.pexels.com/photos/820735/pexels-photo-820735.jpeg?auto=compress&cs=tinysrgb&h=1200" alt="Thumb" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="cs_pagingInfo cs_style_1">iifn</div> */}
        </div>
        <div className="cs_slides_numbers" style={{ display: "block" }}>
          <span className="active">01</span>
          <span className="cs_slide_seperator"> / </span>
          <span className="cs_total_slide" />
        </div>
      </div>
    </section>
  );
};

export default Carousel;
