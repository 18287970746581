import React from "react";

const PageHeading = ({title}) => {
  return (
    <section
      className="cs_page_heading text-center position-relative cs_bg_filed"
      data-src="https://images.pexels.com/photos/1983032/pexels-photo-1983032.jpeg?auto=compress&cs=tinysrgb&w=1920&h=300&dpr=1"
    >
      <div className="cs_hero_overlay position-absolute" />
      <div className="container position-relative">
        <h2 className="cs_fs_50 cs_bold cs_white_color mb-0">{title}</h2>
      </div>
    </section>
  );
};

export default PageHeading;
