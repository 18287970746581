import React from "react";
import PageHeading from "../components/PageHeading";
import Testimonials from "../components/Testimonials";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <PageHeading title={"About"} />
      <section>
        <div className="cs_height_140 cs_height_lg_80" />
        <div className="cs_card cs_style_4">
          <div className="container">
            <div className="row cs_gap_y_30 align-items-center">
              <div className="col-xl-5">
                <div className="cs_card_thumb position-relative">
                  <img
                    src="assets/img/card4.jpg"
                    alt="Card"
                    className=" cs_radius_5 w-100"
                  />
                  <div className="cs_card_badge position-absolute">
                    <h3 className="cs_fs_50 mb-0 cs_bold">Since</h3>
                    <h3 className="cs_fs_50 mb-0 cs_bold">2010</h3>
                    <div className="cs_shape_1">
                      <svg
                        width={16}
                        height={14}
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_395_2354)">
                          <path
                            d="M9.61646 9.6377L1.83244 5.50743C0.535168 4.80239 0.141773 3.10085 1.04759 1.60435C1.95302 0.20786 3.75534 -0.385145 5.05261 0.319899C5.55144 0.621839 5.94989 1.02339 6.14794 1.52416L9.61646 9.6377Z"
                            fill="#5E5E5E"
                          />
                          <path
                            d="M7.80382 12.8301L1.89998 13.8071C0.899212 14.0033 0.00193842 13.2998 0.00582248 12.2998C0.00970653 11.2998 0.713586 10.3025 1.81397 10.2068C2.21435 10.1083 2.51396 10.2095 2.81357 10.3107L7.80382 12.8301Z"
                            fill="#5E5E5E"
                          />
                          <path
                            d="M12.1206 8.54695L11.9435 2.64622C11.9474 1.64623 12.6513 0.648954 13.7517 0.553228C14.7525 0.357113 15.6497 1.0606 15.6459 2.0606C15.6443 2.46059 15.5427 2.8602 15.3416 3.15942L12.1206 8.54695Z"
                            fill="#5E5E5E"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_395_2354">
                            <rect
                              width="15.5"
                              height="13.8"
                              fill="white"
                              transform="translate(15.5 13.8604) rotate(-179.777)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="cs_shape_2">
                      <svg
                        width={16}
                        height={14}
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_395_2350)">
                          <path
                            d="M5.8999 4.2002L13.6999 8.3002C14.9999 9.0002 15.3999 10.7002 14.4999 12.2002C13.5999 13.6002 11.7999 14.2002 10.4999 13.5002C9.9999 13.2002 9.5999 12.8002 9.3999 12.3002L5.8999 4.2002Z"
                            fill="#5E5E5E"
                          />
                          <path
                            d="M7.69995 1.00003L13.6 2.61292e-05C14.6 -0.199974 15.5 0.500026 15.5 1.50003C15.5 2.50003 14.8 3.50003 13.7 3.60003C13.3 3.70003 13 3.60003 12.7 3.50003L7.69995 1.00003Z"
                            fill="#5E5E5E"
                          />
                          <path
                            d="M3.3999 5.2998L3.5999 11.1998C3.5999 12.1998 2.8999 13.1998 1.7999 13.2998C0.799902 13.4998 -0.100098 12.7998 -0.100098 11.7998C-0.100098 11.3998 -9.76473e-05 10.9998 0.199902 10.6998L3.3999 5.2998Z"
                            fill="#5E5E5E"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_395_2350">
                            <rect width="15.5" height="13.8" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 offset-xl-1">
                <div className="cs_card_info">
                  <h2 className="cs_card_title cs_fs_50 cs_bold">
                    Know Our Store
                  </h2>
                  <p className="cs_card_subtitle">
                    Welcome to our online fashion store, where style meets
                    convenience. Explore the latest trends, timeless classics,
                    and everything in between. Elevate your wardrobe with our
                    curated collection of clothing, accessories, and more. Shop
                    with confidence, knowing that quality and fashion are just a
                    click away. Join us in the world of endless possibilities
                    and express your unique style effortlessly. Happy shopping!
                  </p>
                  <div className="cs_card_feature_list">
                    <div className="cs_store_feature position-relative">
                      <h4 className="cs_feature_title cs_fs_21 cs_semibold">
                        Fashion industries leading
                      </h4>
                      <p className="cs_feature_subtitle mb-0">
                        We are a recognized authority in our field, setting
                        industry standards <br /> through expertise and
                        innovation.
                      </p>
                      <div className="cs_feature_icon cs_accent_color position-absolute">
                        <svg
                          width={18}
                          height={17}
                          viewBox="0 0 18 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 9.62275C0.0320847 8.83012 0.390363 8.2499 1.11227 7.93388C1.84487 7.61269 2.54004 7.73702 3.1443 8.25508C4.06406 9.0477 4.96777 9.85587 5.87684 10.6589C6.04796 10.8091 6.21908 10.9645 6.39554 11.1148C6.65757 11.3427 6.88751 11.3323 7.12279 11.0733C9.096 8.91819 11.0639 6.75789 13.0371 4.5976C14.3686 3.13668 15.7054 1.68094 17.037 0.220014C17.2027 0.0386945 17.3952 -0.0493752 17.6412 0.0283334C17.8498 0.0956808 18.0102 0.277001 17.9995 0.494585C17.9941 0.608557 17.946 0.732891 17.8819 0.831322C16.3685 3.19884 14.8552 5.56118 13.3419 7.92352C11.5986 10.6433 9.85534 13.3631 8.11207 16.0881C7.80727 16.5647 7.39551 16.8859 6.81264 16.974C6.15491 17.0776 5.60947 16.8704 5.19237 16.3782C3.69508 14.6116 2.20849 12.8399 0.716557 11.0681C0.433143 10.7469 0.133686 10.4257 0.0534744 9.98539C0.0267372 9.85587 0.0160423 9.73154 0 9.62275Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="cs_store_feature position-relative">
                      <h4 className="cs_feature_title cs_fs_21 cs_semibold">
                        Express your unique style
                      </h4>
                      <p className="cs_feature_subtitle mb-0">
                        We believe in fashion as a form of self-expression. Our
                        diverse range of <br /> products ensures that you can
                        find pieces that reflect your individual style.
                      </p>
                      <div className="cs_feature_icon cs_accent_color position-absolute">
                        <svg
                          width={18}
                          height={17}
                          viewBox="0 0 18 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 9.62275C0.0320847 8.83012 0.390363 8.2499 1.11227 7.93388C1.84487 7.61269 2.54004 7.73702 3.1443 8.25508C4.06406 9.0477 4.96777 9.85587 5.87684 10.6589C6.04796 10.8091 6.21908 10.9645 6.39554 11.1148C6.65757 11.3427 6.88751 11.3323 7.12279 11.0733C9.096 8.91819 11.0639 6.75789 13.0371 4.5976C14.3686 3.13668 15.7054 1.68094 17.037 0.220014C17.2027 0.0386945 17.3952 -0.0493752 17.6412 0.0283334C17.8498 0.0956808 18.0102 0.277001 17.9995 0.494585C17.9941 0.608557 17.946 0.732891 17.8819 0.831322C16.3685 3.19884 14.8552 5.56118 13.3419 7.92352C11.5986 10.6433 9.85534 13.3631 8.11207 16.0881C7.80727 16.5647 7.39551 16.8859 6.81264 16.974C6.15491 17.0776 5.60947 16.8704 5.19237 16.3782C3.69508 14.6116 2.20849 12.8399 0.716557 11.0681C0.433143 10.7469 0.133686 10.4257 0.0534744 9.98539C0.0267372 9.85587 0.0160423 9.73154 0 9.62275Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="/shop"
                    className="cs_btn cs_style_1 cs_fs_16 cs_medium"
                  >
                    Shop Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_140 cs_height_lg_80" />
      </section>
      <Testimonials/>
    </>
  );
};

export default About;
