import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    cartItems: []
},
  reducers: {
    addCartItem: (state, action) => {
      state.cartItems.push(action.payload);
    },
    removeCartItem: (state) => {
      state.cartItems.pop();
    },
    clearCartItems: (state) => {
      state.cartItems.length = 0;
    }
  },
});

// this is for dispatch
export const { addCartItem, removeCartItem, clearCartItems } = productSlice.actions;

// this is for configureStore
export default productSlice.reducer;
