import React from 'react'
import Carousel from '../components/Carousel'
import Services from '../components/Services'
import Banner from '../components/Banner'
import Testimonials from '../components/Testimonials'
import NewProdutcs from '../components/NewProdutcs'

const Home = () => {
  return (
    <>
        <Carousel/>
        <NewProdutcs/>
        <Services/>
        <Banner/>
        <Testimonials/>
    </>
  )
}

export default Home
