import React, { useEffect, useState } from "react";
import { getProducts } from "../api/callAPI";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "./ProductCard";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

const NewProdutcs = () => {
  const [products, setProducts] = useState([]);

  const getNewProducts = async () => {
    const listProducts = await getProducts(10);
    if (listProducts.status) {
      setProducts(listProducts.data);
    }
  };

  useEffect(() => {
    getNewProducts();
  }, [products]);

  return (
    products.length && (
      <div className="mt-5">
        <center>
          <h1>All new products</h1>
        </center>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: false,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          // modules={[Pagination]}
          className="mySwiper mt-5 pt-5"
        >
          {products.length &&
            products.map((product) => (
              <SwiperSlide>
                <ProductCard
                  key={product.ele}
                  name={product.title}
                  image={product.image}
                  price={product.price}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    )
  );
};

export default NewProdutcs;
