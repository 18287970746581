import { baseURL } from "../utils/constants";
import { callApi } from "./api";

const getProducts = async (limit = null) => {
  const url = `${baseURL}/products` + (limit ? `?limit=${limit}` : null);
    console.log('URL ----> ', url);
  const config = {
    method: "get",
    url,
  };

  const response = callApi(config);

  return response;
};

export { getProducts }
