import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({title, image, link}) => {
  return (
    <div className="col-sm-6 my-2">
      <div className="cs_category cs_style_1">
        <Link to={link} className="cs_category_thumb position-relative">
          <img src={image} alt="Category" className="w-100" />
          <span className="cs_category_btn">
            <span>{title}</span>
            <span>
              <i className="fa-solid fa-arrow-right" />
            </span>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
