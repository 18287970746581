import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="cs_footer cs_style_1 cs_primary_bg">
        <div className="cs_height_130 cs_height_lg_80" />
        <div className="container">
          <div className="cs_footer_main">
            <div className="row">
              <div className="col-xxl-3 col-lg-3">
                <div className="cs_footer_widget cs_text_widget logo_size_footer">
                  <img src="assets/img/logo.png" alt="Logo" />
                  <p>
                    Discover endless delights your one stop eCommerce
                    destination.
                  </p>
                </div>
              </div>
              <div className="col-xxl-7 offset-xxl-2 offset-lg-1 col-lg-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="cs_footer_widget cs_menu_widget">
                      <h3 className="cs_footer_widget_title cs_fs_21 cs_semibold">
                        Get to know
                      </h3>
                      <ul>
                        <li>
                          <a href="about.html">About Us</a>
                        </li>
                        <li>
                          <a href="shop.html">Product</a>
                        </li>
                        <li>
                          <a href="blog_details.html">Press</a>
                        </li>
                        <li>
                          <a href="blog.html">Blog</a>
                        </li>
                        <li>
                          <a href="contact.html">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="cs_footer_widget cs_menu_widget">
                      <h3 className="cs_footer_widget_title cs_fs_21 cs_semibold">
                        Customer Service
                      </h3>
                      <ul>
                        <li>
                          <a href="#">Help Center</a>
                        </li>
                        <li>
                          <a href="#">Shipping &amp; Delivery</a>
                        </li>
                        <li>
                          <a href="#">Exchange &amp; Return</a>
                        </li>
                        <li>
                          <a href="#">Payment Method</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="cs_footer_widget cs_menu_widget">
                      <h3 className="cs_footer_widget_title cs_fs_21 cs_semibold">
                        Contact Information
                      </h3>
                      <ul className="cs_contact_info">
                        <li>Call : +00(244)14-50-774</li>
                        <li>Email : info@panchtatvahomes.com</li>
                        <li>Mon – Fri : 11 am – 9 pm</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs_footer_bottom">
            <div>
              <p className="cs_copywrite_text mb-0">
                Copyright &amp; 2023, All rights reserved.
              </p>
            </div>
            <div>
              <ul className="cs_footer_menu_widget_2">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms of Use</a>
                </li>
                <li>
                  <a href="#">Legal</a>
                </li>
              </ul>
            </div>
            <div>
              <div className="cs_social_links">
                <a href="https://www.instagram.com/" target="_blank">
                  <i className="fa-brands fa-instagram" />
                </a>
                <a href="https://www.facebook.com/" target="_blank">
                  <i className="fa-brands fa-facebook" />
                </a>
                <a href="https://twitter.com/" target="_blank">
                  <i className="fa-brands fa-x-twitter" />
                </a>
                <a href="https://www.youtube.com/" target="_blank">
                  <i className="fa-brands fa-youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="cs_scrollup_btn" id="cs_scroll_btn">
        <i className="fa-solid fa-arrow-up" />
      </div>
    </>
  );
};

export default Footer;
