import React from 'react'
import PageHeading from '../components/PageHeading'
import ServiceDetails from '../components/ServiceDetails'

const SingleService = () => {
  return (
    <>
        <PageHeading title={'Single Service'} />
        <ServiceDetails/>
    </>
  )
}

export default SingleService
