import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Root from "./root/Root";
import Shop from "./pages/Shop";
import ServicesPage from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import SingleService from "./pages/SingleService";
import appStore from './state/store/appStore';


import { Provider } from 'react-redux'


function App() {

  const router = createBrowserRouter(
    createRoutesFromElements([
      <Route path="/" element={<Root />} >
        <Route index element={<Home />} />
        <Route path="/shop" element={<Shop/>}/>
        <Route path="/services" element={<ServicesPage/>}/>
        <Route path="/services/:id" element={<SingleService/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Route>
    ])
  )

  return (
    <Provider store={appStore} >
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
