import { configureStore } from '@reduxjs/toolkit';
import cartSlice from '../slice/productSlice';

const appStore = configureStore({
  reducer: {
    cart: cartSlice,
  },
});

export default appStore;
