import React from "react";
import ServiceCard from "./ServiceCard";

const Services = () => {
  return (
    <>
      <div className="m-5 p-5">
        <div className="cs_height_140 cs_height_lg_80" />
        <div className="row cs_gap_y_24">
          <ServiceCard title={'Vastushastra'} image={'https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} link={''}/>
          <ServiceCard title={'Crystal Healing'} image={'https://images.unsplash.com/photo-1630908318796-5998f64b07cf?q=80&w=2144&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} link={''}/>
          <ServiceCard title={'Astrology'} image={'https://images.unsplash.com/photo-1533294455009-a77b7557d2d1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} link={''}/>
          <ServiceCard title={'Numerology'} image={'https://images.pexels.com/photos/4337135/pexels-photo-4337135.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} link={''}/>
        </div>
      </div>
    </>
  );
};

export default Services;
