import React from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <section>
      <div className="cs_height_140 cs_height_lg_80" />
      <div className="container">
        <div className="cs_card cs_style_3">
          <div className="row">
            <div className="col-lg-6">
              <div className="cs_card_thumb">
                <img src="https://images.pexels.com/photos/259588/pexels-photo-259588.jpeg?auto=compress&cs=tinysrgb&w=660&h=530&dpr=1" alt="Card" />
              </div>
            </div>
            <div className="col-lg-6 align-items-center">
              <div className="cs_card_info">
                <h2 className="cs_card_title cs_fs_50 cs_bold">
                  Winter jacket limited time offer 40%
                </h2>
                <p className="cs_card_subtitle cs_fs_21 cs_semibold">
                  Until 02/15/24 Use code WINTER at checkout
                </p>
                <Link
                  to="/shop"
                  className="cs_btn cs_style_1 cs_fs_16 cs_medium"
                >
                  Shop Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
